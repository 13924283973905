<template lang="pug">
modal(size='base' @close='$emit("close")')
  generic-form#FormInquiryAddressAdd(:endpoint='`/_/AddressForm/AddNewAddress?id=${id}&type=${context}&addressType=${type}`' @submitSuccess='$emit("close")')
    template(#default)
      form-header(@cancel='$emit("close")')
        span {{ $t(`Add Address for Inquiry:`) }} &nbsp;
        span(class='font-light') {{ title || id }}
      form-hidden(name='AddressIdentifier')
      form-hidden(name='Type' :modelValue='type')
      form-input(name='AddressName' type='input')
      form-input(name='ContactPerson' type='input' :markOptional='true')
      form-input(name='Phone' type='input' :markOptional='true')
      form-input(v-if='type == "InvoiceAddress" && $permissions.canAccessAdminMenu' name='XentralId' type='input' :markOptional='true')

      h6 {{ $t('Address') }}
      form-input(name='Street' type='input')
      div(class='sm:grid sm:grid-cols-2 sm:gap-4')
        form-input(name='CityCode' type='input')
        form-input(name='City' type='input')
      form-select-native(name='Country' :modelValue='countryPreset' class='mb-8')

      form-footer
        template(#secondary)
          form-checkbox(name='AlsoStoreAtCustomer')
        form-action(:primary='true' name='AddAddress')
</template>

<script lang="ts">
import { preferredCountry } from '@/utilities/Form'
import { defineComponent } from 'vue'

const FormAddressAdd = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    title: { type: String, required: true },
    context: { type: String, required: true },
  },
  setup() {
    const countryPreset = preferredCountry()
    return {
      countryPreset,
    }
  },
})

export default FormAddressAdd
</script>
